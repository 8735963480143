import React from 'react';
import { Dialog, DialogContent, Button, Slide, DialogTitle, DialogContentText, DialogActions } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SuccessPopupProps {
  open: boolean;
  onClose: () => void;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>{"Success"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Prowler Json file sent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessPopup;