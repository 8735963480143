import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import FormComponent from '../components/Form';
import Popup from '../components/Popup';

const Home = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), 3000);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      window.location.replace('https://trackit.io/');
    }, 1200);
  };

  const text = `
    Turn your JSON Prowler report into a PDF business report, with some technical advices to fix it.
  `;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        <Box padding={4} textAlign="justify" maxWidth={500}>
          <Typography variant="h4" gutterBottom>
            Trackit Prowler
          </Typography>
          <Typography variant="body1" paragraph>
            {text}
          </Typography>
        </Box>
        <Box
          padding={4}
          borderRadius={2}
          border="1px solid #dce0e1"
          bgcolor="white"
          boxShadow="0 0 7px #dce0e1"
          minWidth={500}
        >
          <Typography variant="h6" marginBottom={3} gutterBottom color="#626A6D">
            Upload Form
          </Typography>
          <FormComponent onSuccess={handleOpen}/>
          <Popup open={open} onClose={handleClose} />
        </Box>
      </Box>
    </Grid>
  );
};

export default Home;
