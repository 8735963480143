import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import Header from '../components/Header';
import {CssBaseline, Box } from '@mui/material';

const Router = () => (
  <BrowserRouter>
    <CssBaseline />
    <Header />
    <Box display='flex' justifyContent="center" alignItems="center" flex={1}>
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    </Box>
  </BrowserRouter>
);

export default Router;
