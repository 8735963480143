import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const DropZone = styled.div<{ isDragging: boolean, error: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    height: 120px;
    cursor: pointer;
    transition: 0.3s;
    color: ${props => {
        if (props.error) return 'rgb(211, 47, 47)';
        return props.isDragging ? '#000' : 'rgba(0, 0, 0, 0.6)';
    }};
    background-color: ${props => props.isDragging ? '#f7f7f7' : 'transparent'};
    border: 1px dashed #ccc;
    border-color: ${props => {
        if (props.error) return 'rgb(211, 47, 47)';
        return props.isDragging ? '#000' : 'rgba(0, 0, 0, 0.6)';
    }};
    border-radius: 4px;
`;

const UploadedFileContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    max-width: 300px;
    margin-top: 10px;
    border-radius: 4px;
`;

const Filename = styled.span`
    text-overflow: ellipsis;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    flex-grow: 1;
`;

const CloseIcon = styled.span`
    cursor: pointer;
    padding-left: 10px;
`;

const HiddenFileInput = styled.input`
    display: none;
`;

interface Props {
    onFileUpload: (files: FileList) => void;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    error?: boolean;
}

const DragDrop: React.FC<Props> = ({ onFileUpload, onBlur, onChange, error = false }) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleFileUpload = (files: FileList) => {
        onFileUpload(files);
        setUploadedFile(files[0]);
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileUpload(e.dataTransfer.files);
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange) onChange(e);
        if (e.target.files && e.target.files.length > 0) {
            handleFileUpload(e.target.files);
        }
    }

    const removeFile = () => {
        setUploadedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // reset the input
        }
    }


    return (
        <div>
            <DropZone
                error={error}
                isDragging={isDragging}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onClick={() => {
                    if (fileInputRef.current) fileInputRef.current.click();
                }}
                onDrop={handleDrop}
            >
                <HiddenFileInput
                    type="file"
                    accept=".json"
                    onChange={handleFileChange}
                    onBlur={onBlur}
                    ref={fileInputRef}
                    onClick={() => { if (isDragging && fileInputRef.current) fileInputRef.current.click() }}
                />
                Drag & drop or click to upload your json file.
            </DropZone>
            {!error && uploadedFile && (
                <UploadedFileContainer>
                    <Filename>{uploadedFile.name}</Filename>
                    <CloseIcon onClick={removeFile}>✕</CloseIcon>
                </UploadedFileContainer>
            )}
        </div>
    );
}

export default DragDrop;