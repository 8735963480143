import { Box } from "@mui/material";
import styled from "styled-components";
const Logo = require('./trackit-white-logo.png');

const Container = styled(Box)`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  padding: 24px;
`

const Header = () => {
  return (
    <Container bgcolor={'#CD3C2F'}>
      <a href="https://trackit.io/"><img src={Logo} alt="Logo" /></a>
    </Container>
  );
};

export default Header;