import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Stack, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import Api from '../../api';
import DragDrop from './DragDrop';

interface FormData {
  company: string;
  email: string;
  jsonFile: File | null;
}

let handleSuccess = () => {
  console.log('file sent');
}

const validationSchema = yup.object().shape({
  company: yup.string().required('Company Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  jsonFile: yup
    .mixed()
    .required('Please choose a file')
    .test('FILE_FORMAT', 'Upload a Json File.', (values) => {
      if (values instanceof File) return values.type === 'application/json';
      else return false;
    }),
});

const readFileAsync = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error('Failed to read the file'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};

const handleSubmit = async (values: FormData) => {
  try {
    const { company, email, jsonFile } = values;
    if (jsonFile) {
      const prowlerReportJson = JSON.parse(await readFileAsync(jsonFile));

      Api.apiPost({ companyName: company, email, prowlerReportJson });
      setTimeout(handleSuccess, 1000);
    } else {
      throw new Error('file input not founded');
    }
  } catch (error) {
    console.error('Error handling file:', error);
  }
};

interface FormComponentProps {
  onSuccess?: () => void;
}

const FormComponent: React.FC<FormComponentProps> = ({ onSuccess }) => {
  const [fileTouch, setFileTouch] = useState(false);
  if (onSuccess) handleSuccess = onSuccess;
  const initialValues: FormData = {
    company: '',
    email: '',
    jsonFile: null,
  };

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Field name="company">

              {({ field, form }: any) =>
                <FormControl error={form.errors.company && form.touched.company}>
                  <TextField {...field}
                    label="Company"
                    variant="outlined"
                    helperText={form.touched.company ? form.errors.company : undefined}
                    error={!!form.errors.company && form.touched.company}
                  />
                </FormControl>
              }
            </Field>

            <Field name="email">
              {({ field, form }: any) => (
                <FormControl error={form.errors.email && form.touched.email}>
                  <TextField {...field}
                    label="Email"
                    variant="outlined"
                    helperText={form.touched.email ? form.errors.email : undefined}
                    error={!!form.errors.email && form.touched.email}
                  />
                </FormControl>
              )}
            </Field>

            <Field name="jsonFile">
              {({ field, form }: any) => (
                <FormControl error={form.errors.jsonFile && fileTouch}>
                  <DragDrop {...field}
                    error={form.errors.jsonFile && fileTouch}
                    onFileUpload={(files) => {
                      if (!fileTouch) setFileTouch(true);
                      const file = files[0];
                      setFieldValue('jsonFile', file);
                    }}
                  />
                  {fileTouch && (
                    <FormHelperText
                      error={!!form.errors.jsonFile && fileTouch}
                    >
                      {form.errors.jsonFile}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
               {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </Stack>
          </Form>
        )}
      </Formik>
  );
};

export default FormComponent;
