import axios from "axios";

interface apiData {
  companyName: string,
  email: string,
  prowlerReportJson: string []
}

class Api {
  private url = "https://xoiqj5mk70.execute-api.us-west-2.amazonaws.com";

  apiPost = async (object: apiData) => {

    try {
      const response = await axios.post(`${this.url}/convert-json-prowler-report-to-pdf`, object, {
        headers: {},
        withCredentials: false
      })
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();