import Router from './shared/Router';
import { ThemeProvider } from '@mui/material'
import theme from './shared/theme'

import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router/>
    </ThemeProvider>
  );
}

export default App;
